export const EclipsePattern = ({ className }) => (
  <svg
    width="144"
    height="16"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7" stroke="red" fill="none" strokeWidth="1.6" />
    <circle cx="40" cy="8" r="7" stroke="red" fill="none" strokeWidth="1.6" />
    <circle cx="72" cy="8" r="7" stroke="red" fill="none" strokeWidth="1.6" />
    <circle cx="104" cy="8" r="7" stroke="red" fill="none" strokeWidth="1.6" />
    <circle cx="136" cy="8" r="7" stroke="red" fill="none" strokeWidth="1.6" />
  </svg>
);
