export const JavascriptIcon = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.05591 20.2559C1.05591 13.5353 1.05591 10.175 2.36383 7.60802C3.51431 5.35008 5.35008 3.51431 7.60802 2.36383C10.175 1.05591 13.5353 1.05591 20.2559 1.05591H75.7439C82.4645 1.05591 85.8249 1.05591 88.3918 2.36383C90.6497 3.51431 92.4855 5.35008 93.636 7.60802C94.9439 10.175 94.9439 13.5353 94.9439 20.2559V75.7446C94.9439 82.4653 94.9439 85.8256 93.636 88.3925C92.4855 90.6505 90.6497 92.4863 88.3918 93.6367C85.8249 94.9447 82.4645 94.9447 75.7439 94.9447H20.2559C13.5353 94.9447 10.175 94.9447 7.60802 93.6367C5.35008 92.4863 3.51431 90.6505 2.36383 88.3925C1.05591 85.8256 1.05591 82.4653 1.05591 75.7447V20.2559Z"
          fill="#F7DF1E"
        />
        <path
          d="M87.2602 72.5522C86.5724 68.2689 83.7794 64.6711 75.5062 61.3164C72.6322 59.9956 69.4282 59.0499 68.4734 56.8719C68.1344 55.6044 68.0894 54.8904 68.3039 54.1232C68.9197 51.6332 71.8919 50.8569 74.2477 51.5709C75.7649 52.0794 77.2012 53.2487 78.0674 55.1139C82.1189 52.4904 82.1107 52.5077 84.9397 50.7047C83.9039 49.0989 83.3512 48.3579 82.6732 47.6709C80.2364 44.9492 76.9162 43.5474 71.6062 43.6547L68.8402 44.0124C66.1897 44.6821 63.6637 46.0734 62.1824 47.9387C57.7379 52.9816 59.0054 61.8076 64.4137 65.4399C69.7417 69.4389 77.5687 70.3486 78.5684 74.0881C79.5412 78.6661 75.2039 80.1482 70.8929 79.6217C67.7159 78.9609 65.9489 77.3461 64.0387 74.4099C60.5227 76.4446 60.5227 76.4446 56.9077 78.5236C57.7649 80.3979 58.6657 81.2461 60.1027 82.8699C66.9037 89.7684 83.9227 89.4294 86.9752 78.9879C87.0989 78.6294 87.9209 76.2384 87.2602 72.5522ZM52.0964 44.2074H43.3147L43.2787 66.9114C43.2787 71.7399 43.5284 76.1664 42.7432 77.5231C41.4584 80.1917 38.1292 79.8609 36.6119 79.3434C35.0677 78.5844 34.2824 77.5051 33.3727 75.9796C33.1229 75.5416 32.9354 75.2027 32.8724 75.1764L25.7324 79.5489C26.9197 81.9857 28.6687 84.1007 30.9089 85.4747C34.2554 87.4832 38.7532 88.0989 43.4572 87.0189C46.5187 86.1272 49.1602 84.2799 50.5432 81.4682C52.5427 77.7819 52.1137 73.3202 52.0957 68.3852C52.1407 60.3339 52.0964 52.2842 52.0964 44.2074Z"
          fill="#323330"
        />
      </svg>
    </figure>
  );
};
