export const PhotoshopIcon = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6845 31.212C34.923 30.5183 32.1578 30.741 29.2395 31.1955C29.2395 36.2993 29.229 41.2658 29.2672 46.233C29.2687 46.4873 29.6505 46.9298 29.898 46.9635C33.0802 47.3933 36.2452 47.4278 39.2212 45.9773C42.3 44.4773 43.8683 41.3408 43.4618 37.6583C43.0928 34.305 41.1075 32.0723 37.6845 31.212V31.212ZM95.25 47.9723V2.46375C95.25 0.89175 95.2672 0.75 93.741 0.75H2.60925C1.125 0.75 0.75 0.8925 0.75 2.3895V93.5213C0.75 95.0348 0.939 95.037 2.40675 95.037C32.823 95.0378 63.33 95.0303 93.7463 95.0648C94.926 95.0663 95.2425 94.752 95.2395 93.5955C95.199 78.3878 95.25 63.1785 95.25 47.9723V47.9723ZM42.264 52.3523C38.0963 53.8388 33.75 53.922 29.25 53.5733V70.5H21V69.4485C21 55.2773 20.9348 41.1075 20.895 26.937C20.8905 25.7603 21.0773 25.1918 22.4033 25.0568C28.6523 24.4178 34.866 23.6993 41.0797 25.1565C47.7592 26.7218 51.6105 31.1378 51.903 37.239C52.2653 44.7945 48.945 49.9695 42.264 52.3523V52.3523ZM75.4965 61.9178C75.1785 66.246 72.738 69.0878 68.7353 70.4618C63.3788 72.3008 58.0515 71.874 52.8165 69.7935C51.9592 69.4523 51.7935 69.0203 52.0762 68.1465C52.5915 66.552 52.9687 64.9125 53.3662 63.432C55.5795 64.0815 57.684 64.8923 59.8643 65.2755C61.404 65.5463 63.0683 65.3873 64.6335 65.142C66.0368 64.9215 67.0463 63.969 67.2765 62.463C67.5105 60.9323 67.0493 59.6063 65.6978 58.7918C64.6238 58.1453 63.4402 57.6825 62.3055 57.1403C60.4807 56.268 58.536 55.584 56.853 54.4928C50.2298 50.1975 52.386 42.2055 56.8057 39.1958C59.1705 37.5863 61.7872 36.8723 64.5885 36.774C67.8832 36.6585 71.0558 37.227 74.172 38.7413L72.5257 44.583C71.2207 44.121 70.0057 43.521 68.7232 43.2825C67.2015 42.999 65.6055 42.8408 64.0695 42.966C62.01 43.1348 60.804 44.556 60.804 46.3185C60.804 47.3625 61.2 48.246 62.0708 48.7523C63.3203 49.479 64.6462 50.0783 65.9602 50.6865C67.3822 51.3443 68.8837 51.8498 70.2525 52.6005C73.905 54.603 75.8063 57.7005 75.4965 61.9178Z"
          fill="#31A8FF"
        />
      </svg>
    </figure>
  );
};
