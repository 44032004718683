import { palette } from './palette';
import { elevation } from './elevation';
import { typography } from './typography';
import { media } from '../utils/mediaQueries';
import profileImg from 'assets/images/profile/profile-dark_no-bg.png';

export const darkTheme = {
  mode: 'dark',
  palette,
  elevation,
  typography,
  media,
  profileImg,
};
