export const HomeIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0003 8.01221L14.0003 2.75221C13.4503 2.26026 12.7383 1.98828 12.0003 1.98828C11.2624 1.98828 10.5504 2.26026 10.0003 2.75221L4.00034 8.01221C3.68271 8.29629 3.42924 8.64477 3.25681 9.03446C3.08438 9.42414 2.99695 9.84609 3.00034 10.2722V19.0122C3.00034 19.8079 3.31641 20.5709 3.87902 21.1335C4.44163 21.6961 5.20469 22.0122 6.00034 22.0122H18.0003C18.796 22.0122 19.5591 21.6961 20.1217 21.1335C20.6843 20.5709 21.0003 19.8079 21.0003 19.0122V10.2622C21.0023 9.83777 20.9142 9.41775 20.7418 9.02989C20.5694 8.64203 20.3167 8.29517 20.0003 8.01221ZM14.0003 20.0122H10.0003V15.0122C10.0003 14.747 10.1057 14.4926 10.2932 14.3051C10.4808 14.1176 10.7351 14.0122 11.0003 14.0122H13.0003C13.2656 14.0122 13.5199 14.1176 13.7074 14.3051C13.895 14.4926 14.0003 14.747 14.0003 15.0122V20.0122ZM19.0003 19.0122C19.0003 19.2774 18.895 19.5318 18.7074 19.7193C18.5199 19.9069 18.2656 20.0122 18.0003 20.0122H16.0003V15.0122C16.0003 14.2166 15.6843 13.4535 15.1217 12.8909C14.5591 12.3283 13.796 12.0122 13.0003 12.0122H11.0003C10.2047 12.0122 9.44163 12.3283 8.87902 12.8909C8.31641 13.4535 8.00034 14.2166 8.00034 15.0122V20.0122H6.00034C5.73512 20.0122 5.48077 19.9069 5.29323 19.7193C5.1057 19.5318 5.00034 19.2774 5.00034 19.0122V10.2622C5.00052 10.1202 5.03093 9.97991 5.08956 9.85059C5.14818 9.72127 5.23367 9.60592 5.34034 9.51221L11.3403 4.26221C11.5228 4.10189 11.7574 4.01348 12.0003 4.01348C12.2432 4.01348 12.4778 4.10189 12.6603 4.26221L18.6603 9.51221C18.767 9.60592 18.8525 9.72127 18.9111 9.85059C18.9697 9.97991 19.0002 10.1202 19.0003 10.2622V19.0122Z"
        fill="#FFD9E4"
      />
    </svg>
  );
};
