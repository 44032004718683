export const LightBulbIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4212 12.4588C18.5887 11.0213 19.139 9.17965 18.9516 7.33723C18.7642 5.49482 17.8543 3.80175 16.4212 2.62878C15.6002 1.97077 14.6429 1.50375 13.619 1.2616C12.595 1.01945 11.53 1.00824 10.5012 1.22878C9.34606 1.48428 8.27452 2.02838 7.38666 2.81027C6.49879 3.59215 5.82359 4.58631 5.4241 5.69989C5.02461 6.81346 4.91387 8.01012 5.10223 9.1781C5.29059 10.3461 5.7719 11.4473 6.50121 12.3788C7.16589 13.1874 7.54547 14.1926 7.58121 15.2388V15.5288C7.2007 15.8964 6.98136 16.3998 6.97121 16.9288V18.9288C6.97121 19.4592 7.18193 19.9679 7.557 20.343C7.93207 20.7181 8.44078 20.9288 8.97121 20.9288V21.9288C8.97121 22.194 9.07657 22.4484 9.2641 22.6359C9.45164 22.8234 9.706 22.9288 9.97121 22.9288C10.2364 22.9288 10.4908 22.8234 10.6783 22.6359C10.8659 22.4484 10.9712 22.194 10.9712 21.9288V20.9288H12.9712V21.9288C12.9712 22.194 13.0766 22.4484 13.2641 22.6359C13.4516 22.8234 13.706 22.9288 13.9712 22.9288C14.2364 22.9288 14.4908 22.8234 14.6783 22.6359C14.8659 22.4484 14.9712 22.194 14.9712 21.9288V20.9288C15.5016 20.9288 16.0104 20.7181 16.3854 20.343C16.7605 19.9679 16.9712 19.4592 16.9712 18.9288V16.9288C16.9717 16.4055 16.767 15.9029 16.4012 15.5288V15.0988C16.4304 14.1283 16.7903 13.1968 17.4212 12.4588ZM8.97121 18.9288V16.9288H14.9712V18.9288H8.97121ZM15.8612 11.2088C14.976 12.2529 14.4625 13.5613 14.4012 14.9288H9.53121C9.43688 13.5434 8.9125 12.2219 8.03121 11.1488C7.50182 10.4844 7.15043 9.69612 7.01019 8.85828C6.86996 8.02045 6.9455 7.16069 7.22969 6.36015C7.51389 5.5596 7.99736 4.84466 8.63449 4.28279C9.27163 3.72092 10.0414 3.33064 10.8712 3.14878C11.6064 2.98295 12.3696 2.98546 13.1037 3.15614C13.8378 3.32681 14.5238 3.66122 15.1104 4.13438C15.697 4.60753 16.1691 5.20718 16.4914 5.88849C16.8136 6.5698 16.9776 7.31514 16.9712 8.06878C16.9775 9.21266 16.585 10.323 15.8612 11.2088Z"
        fill="#C3C6CF"
      />
    </svg>
  );
};
