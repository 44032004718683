export const Logo = ({ className, idSuffix }) => {
  const filterId = `drop-shadow-${idSuffix}`;
  const gradientId1 = `paint0_linear-${idSuffix}`;
  const gradientId2 = `paint1_linear-${idSuffix}`;
  const gradientId3 = `paint2_linear-${idSuffix}`;
  const gradientId4 = `paint3_linear-${idSuffix}`;
  const gradientId5 = `paint4_linear-${idSuffix}`;
  const gradientId6 = `paint5_linear-${idSuffix}`;
  const gradientId7 = `paint6_linear-${idSuffix}`;
  const gradientId8 = `paint7_linear-${idSuffix}`;
  const gradientId9 = `paint8_linear-${idSuffix}`;
  return (
    <svg
      className={className}
      width="38"
      height="40"
      viewBox="0 0 38 40"
      fill="none"
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter id={filterId} x="-20%" y="-20%" width="150%" height="150%">
        <feDropShadow dx="1" dy="1" stdDeviation="1" floodOpacity="0.3" />
      </filter>
      <g overflow="visible" filter={`url(#${filterId})`}>
        <path
          d="M8.49295 3.34154C7.37203 4.16033 6.35373 5.11105 5.46002 6.1732C5.16644 5.45944 4.70486 4.82712 4.11451 4.33C3.28637 3.63076 2.24375 3.23619 1.16016 3.21195C1.60788 2.69167 2.16288 2.27443 2.78706 1.98887C3.41125 1.70331 4.08983 1.5562 4.77623 1.55763C5.09253 1.55697 5.40805 1.58885 5.71782 1.65275C6.81266 1.87186 7.79526 2.46981 8.49295 3.34154V3.34154Z"
          fill={`url(#${gradientId1})`}
        />
        <path
          d="M5.45515 6.1734C4.32557 7.50953 3.4048 9.00899 2.72414 10.6208C1.90915 10.2357 1.22037 9.62688 0.738078 8.86536C0.255782 8.10383 -0.000180897 7.22094 1.07182e-05 6.31953C-0.00241292 5.17902 0.406267 4.07583 1.15115 3.21216C2.23474 3.23639 3.27736 3.63096 4.1055 4.33021C4.69735 4.82686 5.16039 5.45921 5.45515 6.1734V6.1734Z"
          fill={`url(#${gradientId2})`}
        />
        <path
          d="M36.3435 3.21204C35.2553 3.2372 34.2087 3.63475 33.3782 4.33836C32.7938 4.83524 32.3363 5.46427 32.0437 6.17329C31.1503 5.11 30.132 4.15832 29.0107 3.33887C29.449 2.79218 30.0023 2.34874 30.6313 2.04008C31.2603 1.73142 31.9496 1.56513 32.6501 1.55302C33.3507 1.54091 34.0453 1.68329 34.6846 1.97003C35.3239 2.25676 35.8922 2.68081 36.3491 3.21204H36.3435Z"
          fill={`url(#${gradientId3})`}
        />
        <path
          d="M18.7413 18.2526C17.629 18.7383 16.6791 19.5326 16.0043 20.5414C15.3295 21.5503 14.9579 22.7314 14.9336 23.9449C16.1103 23.3346 17.4164 23.016 18.742 23.016C20.0675 23.016 21.3737 23.3346 22.5504 23.9449C22.526 22.7312 22.1542 21.55 21.4791 20.5412C20.804 19.5323 19.8539 18.7381 18.7413 18.2526V18.2526ZM18.7413 18.2526C17.629 18.7383 16.6791 19.5326 16.0043 20.5414C15.3295 21.5503 14.9579 22.7314 14.9336 23.9449C16.1103 23.3346 17.4164 23.016 18.742 23.016C20.0675 23.016 21.3737 23.3346 22.5504 23.9449C22.526 22.7312 22.1542 21.55 21.4791 20.5412C20.804 19.5323 19.8539 18.7381 18.7413 18.2526V18.2526ZM18.7413 18.2526C17.629 18.7383 16.6791 19.5326 16.0043 20.5414C15.3295 21.5503 14.9579 22.7314 14.9336 23.9449C16.1103 23.3346 17.4164 23.016 18.742 23.016C20.0675 23.016 21.3737 23.3346 22.5504 23.9449C22.526 22.7312 22.1542 21.55 21.4791 20.5412C20.804 19.5323 19.8539 18.7381 18.7413 18.2526V18.2526Z"
          fill={`url(#${gradientId4})`}
        />
        <path
          d="M32.052 6.1829C32.052 6.1829 32.0424 6.1829 32.0396 6.1829C31.0718 6.03647 30.0944 5.96319 29.1156 5.9637C28.7296 5.9637 28.3472 5.97519 27.9686 5.99816C24.6872 6.18606 21.5092 7.21483 18.7402 8.9856C15.9759 7.21801 12.804 6.18982 9.52837 5.99954C9.14236 5.97748 8.75497 5.9637 8.3662 5.9637C7.39159 5.96347 6.41832 6.03628 5.45459 6.18152V6.18152C7.08656 4.24515 9.12183 2.68868 11.4181 1.62095C13.7143 0.553214 16.2161 0 18.7485 0C21.2808 0 23.7826 0.553214 26.0789 1.62095C28.3751 2.68868 30.4104 4.24515 32.0424 6.18152C32.0424 6.18152 32.0493 6.17876 32.052 6.1829Z"
          fill={`url(#${gradientId5})`}
        />
        <path
          d="M36.1414 17.3274C36.0281 19.4658 35.6413 21.5808 34.9903 23.6208C33.1541 29.3676 29.3306 34.2722 24.2055 37.4551C23.6541 37.7997 23.0893 38.1214 22.5112 38.4201C21.8908 38.7413 21.2539 39.0405 20.606 39.3107C19.9975 39.5671 19.3772 39.7969 18.7448 40C18.1144 39.7923 17.4954 39.5625 16.8879 39.3107C16.2344 39.035 15.597 38.7376 14.9757 38.4187C14.3949 38.1173 13.8283 37.7956 13.2759 37.4537C9.8606 35.3384 6.99862 32.4396 4.9272 28.9976C2.85577 25.5555 1.63455 21.6693 1.36476 17.6611C1.36476 17.5673 1.36476 17.4791 1.36476 17.3854C1.35887 13.2823 2.80986 9.31036 5.45922 6.17729C6.42296 6.03206 7.39622 5.95925 8.37084 5.95947C8.7596 5.95947 9.14699 5.97326 9.533 5.99531C12.8086 6.1856 15.9805 7.21378 18.7448 8.98137C16.3042 10.5359 14.2442 12.6191 12.717 15.0769C11.1898 17.5347 10.2345 20.3043 9.92177 23.181C9.89833 23.3781 9.88041 23.578 9.86524 23.7793C9.86524 23.8744 9.86524 23.9668 9.86524 24.0647C9.86546 25.1185 10.1279 26.1557 10.629 27.0827C11.1301 28.0098 11.854 28.7975 12.7355 29.375C13.6171 29.9525 14.6284 30.3015 15.6785 30.3905C16.7285 30.4796 17.7842 30.3059 18.7504 29.8852C20.0471 30.4496 21.495 30.5654 22.8649 30.214C24.2349 29.8627 25.4484 29.0643 26.3134 27.9455C27.1745 26.8357 27.6411 25.4707 27.6396 24.0661C27.6396 23.9682 27.6396 23.8758 27.6396 23.7807C27.6231 23.5808 27.6051 23.3837 27.5845 23.1893V23.1824C27.2718 20.3057 26.3165 17.536 24.7893 15.0782C23.2621 12.6204 21.2021 10.5372 18.7614 8.98275C21.5304 7.21199 24.7083 6.18321 27.9898 5.99531C28.3693 5.97142 28.7517 5.95993 29.1368 5.96085C30.1156 5.96034 31.093 6.03362 32.0608 6.18005C32.0608 6.18005 32.0677 6.18005 32.0732 6.18005C33.2016 7.51448 34.1206 9.01267 34.7987 10.6233C35.078 11.2814 35.3156 11.9564 35.5101 12.6443C35.9327 14.1694 36.1451 15.7449 36.1414 17.3274V17.3274Z"
          fill={`url(#${gradientId6})`}
        />
        <path
          d="M37.4989 6.31936C37.4998 7.07268 37.3217 7.81546 36.9795 8.48655C36.6372 9.15763 36.1405 9.73785 35.5302 10.1795C35.2945 10.3489 35.0445 10.4974 34.783 10.6234C34.1049 9.01274 33.1859 7.51455 32.0575 6.18012C32.0575 6.18012 32.0575 6.18012 32.0479 6.17047C32.3405 5.46146 32.798 4.83243 33.3823 4.33555C34.2128 3.63194 35.2595 3.23439 36.3477 3.20923C37.0932 4.07362 37.5019 5.1779 37.4989 6.31936V6.31936Z"
          fill={`url(#${gradientId7})`}
        />
        <path
          d="M22.5523 24.0729C22.5518 25.3087 22.1909 26.5174 21.5139 27.5512C20.8368 28.585 19.873 29.399 18.7405 29.8934C17.6229 29.4046 16.6689 28.6056 15.9915 27.5911C15.2953 26.5501 14.9248 25.3253 14.9273 24.0729C14.9273 24.0274 14.9273 23.9819 14.9273 23.935C16.104 23.3247 17.4101 23.0062 18.7357 23.0062C20.0612 23.0062 21.3674 23.3247 22.5441 23.935C22.5523 23.9847 22.5523 24.0274 22.5523 24.0729Z"
          fill={`url(#${gradientId8})`}
        />
        <path
          d="M27.6301 24.0731C27.6317 25.4777 27.165 26.8427 26.3039 27.9525V27.9525C25.4389 29.0713 24.2254 29.8697 22.8555 30.221C21.4856 30.5724 20.0376 30.4566 18.7409 29.8922C19.8735 29.3977 20.8372 28.5838 21.5143 27.55C22.1913 26.5162 22.5522 25.3074 22.5528 24.0717C22.5528 24.0262 22.5528 23.9835 22.5528 23.9338C22.5273 22.7211 22.155 21.5411 21.48 20.5333C20.805 19.5255 19.8554 18.7322 18.7437 18.2471C17.63 18.7327 16.679 19.5277 16.0036 20.5377C15.3282 21.5476 14.9567 22.7301 14.9332 23.9449C14.9332 23.9876 14.9332 24.0331 14.9332 24.0827C14.931 25.3313 15.2995 26.5524 15.992 27.5913C16.6686 28.6053 17.6216 29.4043 18.7381 29.8935C17.7721 30.3165 16.7159 30.4919 15.665 30.4038C14.6141 30.3157 13.6017 29.967 12.7196 29.3891C11.8374 28.8112 11.1133 28.0225 10.6128 27.0943C10.1123 26.166 9.85115 25.1276 9.85304 24.0731C9.85304 23.9752 9.85304 23.8828 9.85304 23.7877C9.8682 23.5864 9.88612 23.3865 9.90956 23.1894C10.2223 20.3127 11.1776 17.5431 12.7048 15.0853C14.232 12.6275 16.292 10.5443 18.7326 8.98975C21.1733 10.5442 23.2334 12.6274 24.7606 15.0852C26.2878 17.543 27.243 20.3127 27.5557 23.1894V23.1963C27.5764 23.3907 27.5943 23.5878 27.6109 23.7877C27.6274 23.8828 27.6301 23.9752 27.6301 24.0731Z"
          fill={`url(#${gradientId9})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId1}
          x1="8.49295"
          y1="3.86266"
          x2="1.15464"
          y2="3.86266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0047A9" />
          <stop offset="0.9" stopColor="#5FB6FC" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1="5.45515"
          y1="6.91923"
          x2="0.00414644"
          y2="6.91923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F989C6" />
          <stop offset="0.18" stopColor="#F2A9D7" />
          <stop offset="0.4" stopColor="#ECC9E8" />
          <stop offset="0.62" stopColor="#E7E0F4" />
          <stop offset="0.82" stopColor="#E4EDFB" />
          <stop offset="1" stopColor="#E3F2FE" />
        </linearGradient>
        <linearGradient
          id={gradientId3}
          x1="29.0052"
          y1="3.86274"
          x2="36.3435"
          y2="3.86274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0047A9" />
          <stop offset="0.9" stopColor="#5FB6FC" />
        </linearGradient>
        <linearGradient
          id={gradientId4}
          x1="18.7413"
          y1="23.9394"
          x2="18.7413"
          y2="18.2526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CA0ED" />
          <stop offset="0.03" stopColor="#43A4EE" />
          <stop offset="0.28" stopColor="#7BBFF3" />
          <stop offset="0.51" stopColor="#A8D5F7" />
          <stop offset="0.71" stopColor="#C8E4FA" />
          <stop offset="0.88" stopColor="#DBEEFB" />
          <stop offset="1" stopColor="#E2F1FC" />
        </linearGradient>
        <linearGradient
          id={gradientId5}
          x1="18.7512"
          y1="8.98284"
          x2="18.7512"
          y2="-0.00428379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0047A9" />
          <stop offset="0.9" stopColor="#5FB6FC" />
        </linearGradient>
        <linearGradient
          id={gradientId6}
          x1="18.749"
          y1="40.0069"
          x2="18.749"
          y2="5.96085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0047A9" />
          <stop offset="0.9" stopColor="#5FB6FC" />
        </linearGradient>
        <linearGradient
          id={gradientId7}
          x1="32.0437"
          y1="6.91905"
          x2="37.4947"
          y2="6.91905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F989C6" />
          <stop offset="0.18" stopColor="#F2A9D7" />
          <stop offset="0.4" stopColor="#ECC9E8" />
          <stop offset="0.62" stopColor="#E7E0F4" />
          <stop offset="0.82" stopColor="#E4EDFB" />
          <stop offset="1" stopColor="#E3F2FE" />
        </linearGradient>
        <linearGradient
          id={gradientId8}
          x1="14.93"
          y1="26.4524"
          x2="22.5523"
          y2="26.4524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0047A9" />
          <stop offset="0.23" stopColor="#1B67C1" />
          <stop offset="0.68" stopColor="#4CA0EB" />
          <stop offset="0.9" stopColor="#5FB6FC" />
        </linearGradient>
        <linearGradient
          id={gradientId9}
          x1="18.7423"
          y1="30.4215"
          x2="18.7423"
          y2="8.98285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F989C6" />
          <stop offset="0.18" stopColor="#F2A8D7" />
          <stop offset="0.4" stopColor="#ECC8E8" />
          <stop offset="0.62" stopColor="#E7DFF4" />
          <stop offset="0.82" stopColor="#E4EDFB" />
          <stop offset="1" stopColor="#E3F2FE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
