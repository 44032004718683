export const DevText = ({ className, props }) => {
  return (
    <svg
      width="233"
      height="95"
      viewBox="0 0 233 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M30.36 0a46.43 46.43 0 0 1 32.39 80 46.28 46.28 0 0 1-33.16 13.31H.85a.74.74 0 0 1-.85-.84V.85A.74.74 0 0 1 .65 0h29.71Zm0 81.4a34.75 34.75 0 0 0 .11-69.5H11.9v69.5h18.46ZM116.65 34.93a26.743 26.743 0 0 1 19.83 8.58 27.922 27.922 0 0 1 8.19 20.15v3.71a.75.75 0 0 1-.66.84.405.405 0 0 1-.11 0L101 71.92a18.335 18.335 0 0 0 10.859 9.501 18.332 18.332 0 0 0 14.371-1.301 17.839 17.839 0 0 0 6.63-6.19.808.808 0 0 1 1.06-.39l.13.07 8 4.68a.743.743 0 0 1 .47.92.613.613 0 0 1-.15.26 29.243 29.243 0 0 1-48.17 2.26 29.24 29.24 0 0 1-5.93-17.6 28.63 28.63 0 0 1 8.23-20.81 26.88 26.88 0 0 1 20.15-8.39Zm16.45 23.21a15.442 15.442 0 0 0-5.91-8.91 17.291 17.291 0 0 0-10.73-3.38 16.26 16.26 0 0 0-11.31 4.29 17.49 17.49 0 0 0-5.72 10.92l33.67-2.92ZM193.25 34.85a.773.773 0 0 1 .78-.52h10.07a.87.87 0 0 1 .72.33c.087.109.14.24.153.38a.715.715 0 0 1-.083.4l-21.13 57.09a.808.808 0 0 1-.84.59h-7.42a.768.768 0 0 1-.78-.59l-21.19-57.09a.836.836 0 0 1 .13-.78.712.712 0 0 1 .65-.33h10.08a.829.829 0 0 1 .84.52l14 37.79 14.02-37.79Z"
        fill="#0097FC"
      />
      <path
        d="m221 72 2.79 8.58h9l-7.3 5.32 2.79 8.57-7.28-5.31-7.29 5.31 2.79-8.57-7.3-5.32h9L221 72Z"
        fill="#FC0097"
      />
    </svg>
  );
};
