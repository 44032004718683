export const GitIcon = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.5565 43.7835L52.2188 2.44797C49.8398 0.0674727 45.9788 0.0674727 43.596 2.44797L35.0138 11.0325L45.9023 21.921C48.4335 21.0667 51.3345 21.6397 53.3513 23.6565C54.3124 24.6184 54.9834 25.8315 55.2875 27.1569C55.5916 28.4823 55.5166 29.8665 55.071 31.1512L65.565 41.6467C68.1038 40.7715 71.034 41.337 73.0605 43.368C75.8955 46.2007 75.8955 50.793 73.0605 53.6272C72.3868 54.3013 71.5869 54.836 70.7065 55.2008C69.8261 55.5656 68.8824 55.7533 67.9294 55.7533C66.9764 55.7533 66.0327 55.5656 65.1523 55.2008C64.2719 54.836 63.472 54.3013 62.7983 53.6272C61.7861 52.6144 61.0961 51.3248 60.8151 49.9207C60.5342 48.5166 60.6749 47.0608 61.2195 45.7365L51.4335 35.9497L51.432 61.7055C52.142 62.0551 52.7903 62.5182 53.3513 63.0765C56.1855 65.9092 56.1855 70.5 53.3513 73.3387C50.517 76.1715 45.9233 76.1715 43.092 73.3387C40.2585 70.5007 40.026 65.91 42.8588 63.0765C43.5593 62.3767 44.25 61.848 45 61.494V35.4997C44.25 35.1457 43.56 34.6207 42.858 33.9165C40.7123 31.7715 40.311 28.6215 41.412 25.9845L30.7373 15.2497L2.42177 43.5922C0.0405157 45.975 0.0555157 49.836 2.43677 52.2172L43.7813 93.5527C46.161 95.9332 50.025 95.9332 52.4085 93.5527L93.5535 52.4092C95.9348 50.0272 95.937 46.164 93.5565 43.7835Z"
          fill="#F34F29"
        />
      </svg>
    </figure>
  );
};
