export const BackArrowIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17 10.9999H9.41002L12.71 7.70994C12.8983 7.52164 13.0041 7.26624 13.0041 6.99994C13.0041 6.73364 12.8983 6.47825 12.71 6.28994C12.5217 6.10164 12.2663 5.99585 12 5.99585C11.7337 5.99585 11.4783 6.10164 11.29 6.28994L6.29002 11.2899C6.19898 11.385 6.12761 11.4972 6.08002 11.6199C5.98 11.8634 5.98 12.1365 6.08002 12.3799C6.12761 12.5027 6.19898 12.6148 6.29002 12.7099L11.29 17.7099C11.383 17.8037 11.4936 17.8781 11.6154 17.9288C11.7373 17.9796 11.868 18.0057 12 18.0057C12.132 18.0057 12.2627 17.9796 12.3846 17.9288C12.5065 17.8781 12.6171 17.8037 12.71 17.7099C12.8037 17.617 12.8781 17.5064 12.9289 17.3845C12.9797 17.2627 13.0058 17.132 13.0058 16.9999C13.0058 16.8679 12.9797 16.7372 12.9289 16.6154C12.8781 16.4935 12.8037 16.3829 12.71 16.2899L9.41002 12.9999H17C17.2652 12.9999 17.5196 12.8946 17.7071 12.707C17.8947 12.5195 18 12.2652 18 11.9999C18 11.7347 17.8947 11.4804 17.7071 11.2928C17.5196 11.1053 17.2652 10.9999 17 10.9999Z"
        fill="black"
      />
    </svg>
  );
};
