export const ImagesIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 13V3C16 2.20435 15.6839 1.44129 15.1213 0.87868C14.5587 0.316071 13.7956 0 13 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H13C13.7956 16 14.5587 15.6839 15.1213 15.1213C15.6839 14.5587 16 13.7956 16 13V13ZM2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V7.36L12.92 6.27C12.44 5.78988 11.7889 5.5201 11.11 5.52V5.52C10.4315 5.52228 9.78125 5.79173 9.3 6.27L8.39 7.18L7.58 6.37C7.03194 5.83074 6.29388 5.52851 5.525 5.52851C4.75612 5.52851 4.01806 5.83074 3.47 6.37L2 7.85V3ZM2.12 13.45C2.04289 13.3124 2.00162 13.1577 2 13V10.67L4.88 7.79C4.96456 7.70504 5.06507 7.63762 5.17576 7.59162C5.28644 7.54561 5.40513 7.52193 5.525 7.52193C5.64487 7.52193 5.76356 7.54561 5.87424 7.59162C5.98493 7.63762 6.08544 7.70504 6.17 7.79L7 8.6L2.12 13.45ZM10.72 7.69C10.7693 7.63577 10.8296 7.59259 10.8968 7.56329C10.964 7.53399 11.0367 7.51924 11.11 7.52V7.52C11.1833 7.51924 11.256 7.53399 11.3232 7.56329C11.3904 7.59259 11.4507 7.63577 11.5 7.69L14 10.18V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H4.4L10.72 7.69ZM19 4C18.7348 4 18.4804 4.10536 18.2929 4.29289C18.1054 4.48043 18 4.73478 18 5V15C18 15.7956 17.6839 16.5587 17.1213 17.1213C16.5587 17.6839 15.7956 18 15 18H5C4.73478 18 4.48043 18.1054 4.29289 18.2929C4.10536 18.4804 4 18.7348 4 19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H15C16.3261 20 17.5979 19.4732 18.5355 18.5355C19.4732 17.5979 20 16.3261 20 15V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4Z"
        fill="black"
      />
    </svg>
  );
};
