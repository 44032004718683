export const FigmaIcon = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 96 97"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.125 96.75C43.05 96.75 50.25 89.55 50.25 80.625V64.5H34.125C25.2 64.5 18 71.7 18 80.625C18 89.55 25.2 96.75 34.125 96.75Z"
          fill="#0ACF83"
        />
        <path
          d="M18 48.375C18 39.45 25.2 32.25 34.125 32.25H50.25V64.5H34.125C25.2 64.5 18 57.3 18 48.375Z"
          fill="#A259FF"
        />
        <path
          d="M18 16.125C18 7.2 25.2 0 34.125 0H50.25V32.25H34.125C25.2 32.25 18 25.05 18 16.125Z"
          fill="#F24E1E"
        />
        <path
          d="M50.25 0H66.375C75.3 0 82.5 7.2 82.5 16.125C82.5 25.05 75.3 32.25 66.375 32.25H50.25V0Z"
          fill="#FF7262"
        />
        <path
          d="M82.5 48.375C82.5 57.3 75.3 64.5 66.375 64.5C57.45 64.5 50.25 57.3 50.25 48.375C50.25 39.45 57.45 32.25 66.375 32.25C75.3 32.25 82.5 39.45 82.5 48.375Z"
          fill="#1ABCFE"
        />
      </svg>
    </figure>
  );
};
