export const AdobeIllustrator = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="illustrator-icon__path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7835 51.8024H47.4932C45.4774 44.9462 43.4922 38.608 41.5389 32.1662C39.6436 38.6309 37.7208 44.9462 35.7835 51.8024ZM93.7999 0H1.44008C-0.225218 0 0.0132259 0.362617 0.0132259 1.96925V94.4472C0.0132259 96.0523 -0.225217 95.9868 1.32276 95.9868H93.9172C95.4645 95.9868 96 96.0523 96 94.4464V2.08657C96 0.362617 95.4652 0 93.7999 0ZM58.137 72.371C56.89 72.371 55.6383 72.2697 54.3974 72.3588C53.5022 72.4258 53.2029 72.0495 52.9644 71.2725C51.811 67.516 50.5853 63.7611 49.4312 60.0054C49.1988 59.2497 48.8667 58.974 48.0477 58.9839C43.8403 59.0326 39.6306 59.0281 35.4224 58.9785C34.5365 58.9679 34.1289 59.204 33.876 60.0831C32.8049 63.819 31.6332 67.5244 30.5507 71.2565C30.3161 72.0655 29.9596 72.3481 29.1003 72.326C26.5726 72.2628 24.0412 72.3017 21.2735 72.3017C22.0041 69.982 22.6669 67.8367 23.3525 65.7007C27.553 52.5977 31.7681 39.4947 35.9382 26.3834C36.2292 25.4708 36.6337 25.1897 37.5707 25.2102C40.5311 25.2758 43.4937 25.227 46.4556 25.2102C47.0094 25.2064 47.3873 25.2392 47.5899 25.8684C52.5454 41.1928 57.5184 56.6116 62.4869 71.9345C62.5082 72.0038 62.4945 72.371 62.5059 72.371H58.137ZM73.9078 72.371H64.7662V38.09H73.9078V72.371ZM69.0704 33.4651C66.2784 33.4613 64.2794 31.5393 64.2604 28.841C64.2421 26.1503 66.3226 24.1491 69.1405 24.146C71.9804 24.143 73.9817 26.0787 74.0038 28.8471C74.0236 31.5477 71.9721 33.4697 69.0704 33.4651Z"
          fill="#FAA625"
        />
      </svg>
    </figure>
  );
};
