import { palette } from './palette';
import { elevation } from './elevation';
import { typography } from './typography';
import { media } from '../utils/mediaQueries';
import profileImg from 'assets/images/profile/profile-light_no-bg.png';

export const lightTheme = {
  mode: 'light',
  palette,
  elevation,
  typography,
  media,
  profileImg,
};
