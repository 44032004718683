import { css } from 'styled-components';

export const typography = {
  hero: {
    large: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 10.3rem;
      line-height: 11.6rem;
    `,
    medium: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 9.1rem;
      line-height: 10.4rem;
    `,
    small: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 8.1rem;
      line-height: 9.2rem;
    `,
  },
  display: {
    large: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 5.7rem;
      line-height: 6.4rem;
    `,
    medium: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 4.5rem;
      line-height: 5.2rem;
    `,
    small: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 3.6rem;
      line-height: 4.4rem;
    `,
  },
  headline: {
    large: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 3.2rem;
      line-height: 4rem;
    `,
    medium: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 2.8rem;
      line-height: 3.6rem;
    `,
    small: css`
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 450;
      font-size: 2.4rem;
      line-height: 3.2rem;
    `,
  },
  title: {
    large: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2.2rem;
      line-height: 2.8rem;
    `,
    medium: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015rem;
    `,
    small: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.01rem;
    `,
  },
  label: {
    large: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.01rem;
    `,
    medium: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.05rem;
    `,
    small: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1.6rem;
      letter-spacing: 0.05rem;
    `,
  },
  body: {
    xlarge: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: 0.05rem;
    `,
    large: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.05rem;
    `,
    medium: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.025rem;
    `,
    small: css`
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.04rem;
    `,
  },
};
