export const DotPattern = ({ className }) => {
  return (
    <svg
      className={className}
      width="84"
      height="84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="red" />
      <circle cx="22" cy="2" r="2" fill="red" />
      <circle cx="42" cy="2" r="2" fill="red" />
      <circle cx="62" cy="2" r="2" fill="red" />
      <circle cx="82" cy="2" r="2" fill="red" />

      <circle cx="2" cy="22" r="2" fill="red" />
      <circle cx="22" cy="22" r="2" fill="red" />
      <circle cx="42" cy="22" r="2" fill="red" />
      <circle cx="62" cy="22" r="2" fill="red" />
      <circle cx="82" cy="22" r="2" fill="red" />

      <circle cx="2" cy="42" r="2" fill="red" />
      <circle cx="22" cy="42" r="2" fill="red" />
      <circle cx="42" cy="42" r="2" fill="red" />
      <circle cx="62" cy="42" r="2" fill="red" />
      <circle cx="82" cy="42" r="2" fill="red" />

      <circle cx="2" cy="62" r="2" fill="red" />
      <circle cx="22" cy="62" r="2" fill="red" />
      <circle cx="42" cy="62" r="2" fill="red" />
      <circle cx="62" cy="62" r="2" fill="red" />
      <circle cx="82" cy="62" r="2" fill="red" />

      <circle cx="2" cy="82" r="2" fill="red" />
      <circle cx="22" cy="82" r="2" fill="red" />
      <circle cx="42" cy="82" r="2" fill="red" />
      <circle cx="62" cy="82" r="2" fill="red" />
      <circle cx="82" cy="82" r="2" fill="red" />
    </svg>
  );
};
