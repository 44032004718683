export const TypeScriptIcon = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="100%"
        height="100%"
        viewBox="0 0 96 96"
      >
        <path
          fill="#3178C6"
          d="M7.5.81h81a7.5 7.5 0 0 1 7.5 7.5v81a7.5 7.5 0 0 1-7.5 7.5h-81a7.5 7.5 0 0 1-7.5-7.5v-81A7.5 7.5 0 0 1 7.5.81Z"
        />
        <path
          fill="#fff"
          d="M56.444 75.987v10.358c1.685.863 3.677 1.51 5.977 1.942 2.3.431 4.724.647 7.272.647 2.484 0 4.843-.237 7.078-.712 2.235-.475 4.195-1.257 5.88-2.346a11.964 11.964 0 0 0 4-4.273c.983-1.758 1.474-3.932 1.474-6.522 0-1.877-.28-3.523-.842-4.936a11.527 11.527 0 0 0-2.43-3.77c-1.058-1.101-2.327-2.088-3.806-2.962-1.48-.874-3.147-1.7-5.005-2.476a63.16 63.16 0 0 1-3.66-1.635c-1.08-.528-1.998-1.068-2.754-1.618-.755-.55-1.339-1.133-1.749-1.748a3.677 3.677 0 0 1-.615-2.088c0-.712.183-1.353.55-1.925.367-.572.886-1.063 1.555-1.473.67-.41 1.49-.728 2.462-.955.972-.226 2.052-.34 3.24-.34a21.573 21.573 0 0 1 5.637.793c.97.27 1.915.61 2.833 1.02.918.41 1.766.885 2.543 1.424v-9.678c-1.576-.604-3.299-1.052-5.166-1.343-1.868-.291-4.012-.437-6.43-.437-2.463 0-4.795.265-6.997.793-2.203.529-4.142 1.354-5.815 2.476a12.379 12.379 0 0 0-3.968 4.289c-.972 1.737-1.458 3.814-1.458 6.23 0 3.086.89 5.718 2.673 7.898 1.781 2.18 4.486 4.024 8.114 5.535a109.18 109.18 0 0 1 3.984 1.715c1.231.561 2.295 1.144 3.191 1.748.896.604 1.604 1.262 2.122 1.974.518.712.777 1.522.777 2.428 0 .669-.162 1.29-.486 1.861-.323.572-.815 1.068-1.474 1.489-.658.42-1.479.75-2.461.987-.983.237-2.133.356-3.45.356-2.246 0-4.47-.394-6.673-1.182-2.203-.787-4.244-1.968-6.123-3.544ZM39.181 50.212H52.5v-8.528H15.375v8.528h13.254v37.972h10.552V50.212Z"
        />
      </svg>
    </figure>
  );
};
