export const CssIcon = ({ className }) => {
  return (
    <figure className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1103 85.5919L6.56982 1.01367H89.4298L81.8818 85.5792L47.9496 94.9864L14.1103 85.5919Z"
          fill="#1572B6"
        />
        <path
          d="M48.0007 87.7967L75.42 80.1947L81.8707 7.9292H48.0007V87.7967Z"
          fill="#33A9DC"
        />
        <path
          d="M48.0007 38.5714H61.7272L62.6752 27.9492H48.0007V17.5759H74.0122L73.7632 20.3592L71.2132 48.9447H48.0007V38.5714Z"
          fill="white"
        />
        <path
          d="M48.0622 65.5118L48.0165 65.5253L36.4642 62.4061L35.7255 54.1328H25.314L26.7667 70.4206L48.015 76.3178L48.0622 76.3043V65.5118Z"
          fill="#EBEBEB"
        />
        <path
          d="M60.8454 48.5063L59.5959 62.3978L48.0264 65.5208V76.3133L69.2919 70.4198L69.4479 68.6671L71.2524 48.5063H60.8454Z"
          fill="white"
        />
        <path
          d="M48.0358 17.5759V27.9492H22.9798L22.772 25.6182L22.2995 20.3592L22.0513 17.5759H48.0358ZM48.0005 38.5729V48.9462H36.5938L36.386 46.6152L35.9128 41.3562L35.6653 38.5729H48.0005Z"
          fill="#EBEBEB"
        />
      </svg>
    </figure>
  );
};
