import { generateElevations } from 'themes/utils/generateElevations';

/**
 * LEVELS object maps elevation levels to their corresponding names.
 * This makes it easier to refer to specific elevation levels in a more readable manner.
 */
const LEVELS = {
  LVL0: 'level0',
  LVL1: 'level1',
  LVL2: 'level2',
  LVL3: 'level3',
  LVL4: 'level4',
  LVL5: 'level5',
};

/**
 * SHADOW object contains the box-shadow definitions for each elevation level.
 * The keys are the elevation levels, and the values are the corresponding box-shadow styles.
 */
const SHADOW = {
  [LEVELS.LVL0]: 'none',
  [LEVELS.LVL1]:
    '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)',
  [LEVELS.LVL2]:
    '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)',
  [LEVELS.LVL3]:
    '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
  [LEVELS.LVL4]:
    '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
  [LEVELS.LVL5]:
    '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
};

/**
 * Export the elevation styles generated by the generateElevations utility function.
 * This will be used to extend the theme object.
 */
export const elevation = generateElevations(LEVELS, SHADOW);

